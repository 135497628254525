import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap, take } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { UserRole } from './user-role.enum';

@Injectable({ providedIn: 'root' })
export class AuthGuard  {

  private exceptions: string[] = [
    '/inspection'
  ];

  constructor(private authService: AuthService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    router: RouterStateSnapshot
  ):
    | boolean
    | UrlTree
    | Promise<boolean | UrlTree>
    | Observable<boolean | UrlTree> {
      if (this.exceptions.includes('/'+this.pathName())) {
        return this.router.createUrlTree([window.location.pathname]);
      }

      return this.authService.userAuth.pipe(
      take(1),
      map(user => {
        const isAuth = !!user;
        if (isAuth) {
          if (!this.authService.isTokenExpired()) {
            return true;
          }
        }
        return this.router.createUrlTree(['/login']);
      })
    );
  }

  pathName(): string{
    const pathName = window.location.pathname;
    const pathParts: string[] = pathName.split('/');
    return pathParts[1];

  };
}
