import { Injectable } from '@angular/core';
import { PhotoLabel } from './photo-labels/classes/photo-label.class';
import * as Http from 'http';
import {HttpClient} from '@angular/common/http';
import {PhotoLabelsComponent} from './photo-labels/photo-labels.component';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  constructor(private http: HttpClient) { }

  getPhotoLabels(): Promise<PhotoLabel[]> {
    return this.http.get('/api/settings/photo-labels')
      .toPromise()
      .then(data => {
        return data as PhotoLabel[];
      });
  }

  savePhotoLabels(photoLabels: any): Promise<PhotoLabel[]> {
    return this.http.put(`/api/settings/photo-labels`, photoLabels)
      .toPromise()
      .then( data => {
        return data as PhotoLabel[];
      });
  }

  syncImageCaptions(): Promise<any> {
    return this.http.get('/api/legacy-transfer/sync-image-captions-table')
      .toPromise()
      .then(res => {
        console.log(res);
      })
      .catch(e => {
        console.log(`Unable to sync image captions on legacy data API.\n${e}`);
      });
  }
}
