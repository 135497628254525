import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { INote } from './interfaces/note.interface';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotesService {

  constructor(private http: HttpClient) { }

  async getScJobNotesById(scJobId: number): Promise<any[]|void> {
    try {
      const notes = await firstValueFrom( this.http.get(`/api/notes/${scJobId}`) ) as Promise<any[]|undefined>;
      if (notes) {
        return notes;
      }
     } catch (e) { 
      console.error(`Unable to get job notes.\n${e}`) 
    }
  }

  async createScJobNote(scJobId: number, scJobNote: string, isPrivate: boolean): Promise<INote|void> {
    try {
      const note = await firstValueFrom( 
        this.http.post(`/api/notes/${scJobId}`, { 
          scJobNote,
          isPrivate 
        }) 
      ) as INote|undefined;
      if (note) {
        return note;
      }
     } catch (e) { 
      console.error(`Unable to create job note.\n${e}`)
    }
  }
}

