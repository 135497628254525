<p-confirmDialog key="formConfirmDialog" header="Form Action Confirmation" width="425" icon="pi pi-exclamation-triangle"></p-confirmDialog>
<div class="flex flex-column h-100" *ngIf="!isSaveFinal && !isFormClosed">
  <div [ngClass]="{'form-container-with-buttons': showButtons, 'form-container': !showButtons}">
    <div class="h-100 border-gray-200 border-round border-2 shadow-5 p-3" #formViewContent>
      <p-scrollPanel #scrollBar [style]="{ 'width': '100%', 'height': '100%' }">
        <formio #f [form]="formDef" (ready)="formReady(f)" [submission]="formData" (change)="onFormChange($event)"
          (submit)="onSubmit(f, $event)" (errorChange)="onFormInvalid($event)" [formioOptions]="formioOptions" (customEvent)="onCustomEvent(f, $event)">
        </formio>
      </p-scrollPanel>
    </div>
  </div>
  <div *ngIf="showButtons" class="button-container">
    <div class="flex flex-row flex-row-wrap">
      <p-button label="Close" class="p-button-danger"></p-button>
    </div>
  </div>
</div>
<div class="p-panel" *ngIf="isSaveFinal">
  <div class="p-panel-content">
    <h5>Thank you for completing this inspection form. Your form has been been saved and submitted for review.</h5>
    <h5>If you are finished uploading photos, you can close this window.</h5>
  </div>
</div>
<div class="p-panel" *ngIf="isFormClosed">
  <div class="p-panel-content">
    <h5>The inspection form has been closed. You can reopen it by clicking the button below.</h5>
    <h5>If you are finished uploading photos, you can close this window.</h5>
    <p-button (onClick)="reopenForm()">Open Inspection Form</p-button>
  </div>
</div>