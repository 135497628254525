import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UserRole, roleNames } from './roles.enum';
import { LI_UserProfileXRef, UserProfile } from 'csi-sitecheck-types/dist/prisma-client';
import { UserProfileDto, UserProfilesDto, UserProfilesResponse } from 'csi-sitecheck-types/dist/types/user-profile.types'; 
import { firstValueFrom } from 'rxjs';
import { HttpContext } from '@angular/common/http';
@Injectable({
  providedIn: 'root',
})
export class UsersService {

  roleNames = roleNames;
  
  constructor(private http: HttpClient) { }

  getRoles(): string[] {
    return Object.keys(UserRole).filter(
      (type) => isNaN(<any>type) && type !== 'values'
    );
  }

  getRoleEnumName(role: UserRole): string {
    return UserRole[role];
  }

  getRoleName(role: UserRole): string {
    return this.roleNames[role];
  }

  // async getUserProfiles(cursor: number, take: number): Promise<UserProfilesResponse|void> {
  //   const userProfilesResponse = await firstValueFrom( this.http.get(`/api/user-profile?cursor=${cursor}&take=${take}`) ) as UserProfilesResponse;
  //   if (userProfilesResponse  && userProfilesResponse?.userProfiles.length > 0) {
  //     return userProfilesResponse;
  //   }
  // }

  async getUserProfiles(): Promise<UserProfilesResponse|void> {
    const userProfilesResponse = await firstValueFrom( this.http.get(`/api/user-profile`) ) as UserProfilesResponse;
    if (userProfilesResponse  && userProfilesResponse?.userProfiles.length > 0) {
      return userProfilesResponse;
    }
  }
  
  async getUserProfileById(userProfileId: number): Promise<UserProfileDto|void> {
    const userProfile = await firstValueFrom( this.http.get(`/api/user-profile/${userProfileId}`) ) as UserProfileDto|void;
    if (userProfile) {
      return userProfile;
    }
  }
}