import { Injectable } from '@angular/core';
import { AuthService } from './auth/auth.service';
import { HttpClient } from '@angular/common/http';
@Injectable({
    providedIn: 'root',
})
export class AppService {
    private _currentUser: any;
    private _currentUserProfile: any;

    public get currentUser(): any {
        return this._currentUser;
    }

    public set currentUser(value: any) {
        this._currentUser = value;
    }

    public get currentUserProfile(): any {
        return this._currentUserProfile;
    }

    public set currentUserProfile(value: any) {
        this._currentUserProfile = value;
    }

    constructor(
        private auth: AuthService,
        private http: HttpClient,
    ) {}

    async getUserInfo(): Promise<any|void> {
        let token = this.auth.getToken();
        if (token) {
            let decoded = this.auth.decodeToken(token);
            if (decoded) {
                if (decoded.userProfileId) {
                    const userProfile = await this.http.get(`/api/users/profile/${decoded.userProfileId}`).toPromise();
                    if (userProfile) {
                        this.currentUser = {
                            ...decoded,
                            ...userProfile
                        }
                        return this.currentUser;
                    } else {
                        this.currentUser = decoded;
                        return this.currentUser;
                    }
                } else {
                  this.currentUser = decoded;
                  return this.currentUser;
                }
            }
        }
    }
}