import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { catchError, tap } from 'rxjs/operators';
import { throwError, BehaviorSubject } from 'rxjs';
import { UserAuth } from './user-auth';
import { jwtDecode, JwtPayload } from 'jwt-decode';

export interface AuthLoginResponseData {
  accessToken: string;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public userAuth: BehaviorSubject<UserAuth>;

  constructor(private http: HttpClient, private router: Router) {
    this.userAuth = new BehaviorSubject<UserAuth>(JSON.parse(localStorage.getItem('userAuth') || '{}'));
  }

  login(loginName: string, password: string) {
    return this.http
      .post<AuthLoginResponseData>(
        '/api/auth/login',
        {
          loginName,
          password,
        }
      )
      .pipe(
        catchError(this.handleError),
        tap(resData => {
          this.handleAuthentication(
            resData.accessToken
          );
        })
      );
  }

  private handleAuthentication(accessToken: string) {
    const userAuth = new UserAuth(accessToken);
    this.userAuth.next(userAuth);
    localStorage.setItem('userAuth', JSON.stringify(userAuth));
  }

  logout() {
    this.userAuth.next({} as UserAuth);
    localStorage.removeItem('userAuth');
    this.router.navigate(['/login']);
  }

  getToken(): string {
    let userAuth = localStorage.getItem('userAuth');
    if (userAuth) {
      let authJson = JSON.parse(userAuth);
      return authJson.accessToken
    }
    return '';
  }

  getTokenExpirationDate(token: string): Date|void {
    const decoded = jwtDecode<JwtPayload>(token);

    if (decoded.exp === undefined) return;

    const date = new Date(0); 
    date.setUTCSeconds(decoded.exp);
    return date;
  }

  isTokenExpired(token?: string): boolean {
    if(!token) token = this.getToken();
    if(!token) return true;

    const date = this.getTokenExpirationDate(token);
    if(date === undefined) return false;
    return !(date.valueOf() > new Date().valueOf());
  }

  private handleError(errorRes: HttpErrorResponse) {
    return throwError(errorRes.error ? errorRes.error.message : 'An unknown error occurred!');
  }

  decodeToken(token: string): any|void {
    return jwtDecode<JwtPayload>(token);
  }

}
