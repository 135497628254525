import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpRequest} from '@angular/common/http';
import { IFile } from './upload/upload.models';
import {Observable} from 'rxjs';
import {AppConfigService} from '../app-config.service';
import {PhotoLabel} from '../settings/photo-labels/classes/photo-label.class';
import {SettingsService} from '../settings/settings.service';

@Injectable({
  providedIn: 'root'
})
export class PhotosService {

  constructor(
    private http: HttpClient,
    private appConfigService: AppConfigService,
    private settingsService: SettingsService
  ) {
  }

  getSignedUrls(files: IFile[]): Promise<any> {
    return this.http.post(`/api/aws/sign-for-put/`, files)
      .toPromise()
      .then( data => {
        return data;
      });
    }

    uploadPhotoS3(file: any): Observable<any> {
      const headers = new HttpHeaders({
        'Content-Type': file.type,
      });
      const uploadReq = new HttpRequest(
        'PUT',
        file.url,
        file,
        {
          headers,
          reportProgress: true,
        }
      );
      return this.http.request(uploadReq);
    }

    savePhotos(jobId: string, photos: any): Promise<any> {
      return this.http.put(`/api/jobs/${jobId}`, { inspectionPhotos: photos })
        .toPromise()
        .then(job => {
          return job;
        });
    }

    getJob(jobId: string): Promise<any> {
      return this.http.get(`/api/jobs/${jobId}`)
        .toPromise()
        .then(job => {
          return job;
        });
    }

    getS3Photo(url: string): Promise<any> {
      return this.http.get(url, { responseType: 'arraybuffer'})
        .toPromise()
        .then(res => {
          // console.log(res);
          return res;
        });
    }

    deleteS3Photo(bucket: string, key: string): Promise<any> {
      return this.http.post('/api/aws/delete-s3-object', { bucket, key })
        .toPromise()
        .then(res => {
          // console.log(res);
          return res;
        });
    }

    getS3PhotoBucket(): Promise<any> {
      return this.http.get('/api/aws/get-s3-bucket-name')
        .toPromise()
        .then( data => {
          // @ts-ignore
          return data.bucket;
        })
        .catch( err => {
          console.log(err);
        });
    }

    getPhotoLabels(): Promise<PhotoLabel[]> {
      return this.settingsService.getPhotoLabels()
        .then(photoLabels => {
          return photoLabels;
        });
      }

}
